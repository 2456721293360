import { NgModule } from '@angular/core';
import { PropertyAlertDialogComponent } from './property-alert-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyAlertsFacade } from '@feature/property-alerts/property-alerts.facade';

@NgModule({
	declarations: [PropertyAlertDialogComponent],
	imports: [SharedModule, ButtonModule, MatDialogModule, MatSelectModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
	exports: [PropertyAlertDialogComponent],
	providers: [PropertyAlertsFacade],
})
export class PropertyAlertDialogModule {}
